/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import cx from 'classnames';
import T from 'i18n-react';

import { LogoBuyer } from '@motorway/motorway-component-library';

import Content from 'Storybook/local/Content/Content';

import texts from '../texts.json';

import reviews from './Testimonials.json';

import styles from './Testimonials.module.scss';

T.setTexts(texts);

class Testimonials extends React.Component {
	componentDidMount() {
		this.initSwiper();
	}

	initSwiper() {
		import(/* webpackChunkName: "swiper" */ 'swiper')
			.then(({ default: Swiper }) => {
				this.scroller = new Swiper(`.swiper-container.${styles.carousel}`, {
					centerInsufficientSlides: true,
					direction: 'horizontal',
					loop: false,
					navigation: {
						nextEl: `.swiper-button-next.${styles.nextButton}`,
						prevEl: `.swiper-button-prev.${styles.prevButton}`,
					},
					// hide pagination in case of only one page
					pagination: {
						clickable: true,
						el: `.swiper-pagination.${styles.pagination}`,
					},

					slidesPerView: 'auto',
					watchOverflow: true,
				});
			})
			.catch((error) => `An error occurred while loading the component; ${error}`);
	}

	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<Content
				center
				className={styles.testimonials}
				element="section"
				wrapper={{
					className: styles.testimonialsWrapper,
				}}
			>
				<h2>{T.translate('homePage.testimonials.title')}</h2>
				<div className={styles.carouselWrapper}>
					<div className={cx('swiper-container', styles.carousel)}>
						<div className="swiper-wrapper">
							{reviews.map((r) => (
								<div key={r.number} className={cx('swiper-slide', styles.carouselItem)}>
									<LogoBuyer buyer={r.buyerSlug} />
									<p>{`"${r.body}"`}</p>
									<h6>{r.buyerName}</h6>
								</div>
							))}
						</div>
						<div className={cx('swiper-pagination', styles.pagination)} />
					</div>
				</div>
			</Content>
		);
	}
}

export default Testimonials;
