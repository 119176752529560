import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import T from 'i18n-react';

import { MakeLogo } from 'Storybook/core/MakeLogo/MakeLogo';
import Content from 'Storybook/local/Content/Content';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import texts from '../texts.json';
import { canUseWebP } from '../Utilities';

import { RenderMakeRowsParam } from './MakesCarousel.types';

import styles from './MakesCarousel.module.scss';

T.setTexts(texts);

const makeNames: { [key: string]: string } = {
	audi: 'Audi',
	bmw: 'BMW',
	ford: 'Ford',
	'land-rover': 'Land Rover',
	mercedes: 'Mercedes',
	porsche: 'Porsche',
	renault: 'Renault',
	toyota: 'Toyota',
	volkswagen: 'Volkswagen',
};

const desktopMakes = [['toyota', 'renault', 'porsche', 'ford', 'bmw', 'land-rover', 'mercedes', 'volkswagen', 'audi']];

const tabletMakes = [
	['porsche', 'ford', 'bmw', 'land-rover', 'mercedes'],
	['toyota', 'renault', 'volkswagen', 'audi'],
];

const mobileMakes = [
	['ford', 'bmw', 'land-rover'],
	['toyota', 'renault', 'porsche', 'mercedes', 'volkswagen', 'audi'],
];

const MakesCarousel: React.FC = () => {
	const [loadImages, setLoadImages] = useState(false);
	const [webpSupport, setWebpSupport] = useState(false);
	const carouselRef = useRef<HTMLDivElement>(null);
	const isNewLandingPageEnabled: boolean = useFeatureToggle(FEATURES.newLandingPage);

	useEffect(() => {
		if ('IntersectionObserver' in window) {
			const options = {
				rootMargin: '400px',
				threshold: 0,
			};

			const observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setLoadImages(true);
					setWebpSupport(canUseWebP());
					observer.disconnect();
				}
			}, options);

			if (carouselRef.current) {
				observer.observe(carouselRef.current);
			}

			return () => {
				observer.disconnect();
			};
		}
		setLoadImages(true);

		return undefined;
	}, []);

	const renderMakeRows = (breakpoint: RenderMakeRowsParam = 'desktop') => {
		const makeRowsMap = {
			desktop: desktopMakes,
			mobile: mobileMakes,
			tablet: tabletMakes,
		};

		const makeRows = makeRowsMap[breakpoint] || [];

		return (
			<div className={styles[`${breakpoint}Rows`]}>
				{makeRows.map((row) => (
					<ul key={row.join('-')}>
						{row.map((slug) => (
							<li key={slug}>
								<MakeLogo
									className={cx('mw-i')}
									make={loadImages ? slug : ''}
									title={makeNames[slug]}
									webp={webpSupport}
								/>
							</li>
						))}
					</ul>
				))}
			</div>
		);
	};

	return (
		<section
			ref={carouselRef}
			className={`${styles.makesCarousel} ${isNewLandingPageEnabled ? styles.newMakesCarousel : ''}`}
		>
			<Content center element="h3">
				{T.translate(isNewLandingPageEnabled ? 'homePage.makesCarousel.newTitle' : 'homePage.makesCarousel.title')}
			</Content>

			{renderMakeRows('desktop')}
			{renderMakeRows('tablet')}
			{renderMakeRows('mobile')}
		</section>
	);
};

export default MakesCarousel;
