/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Head from 'next/head';
import { withRouter } from 'next/router';

import { isProd } from 'Utilities/helpers';

import metaImage from '../../public/static/images/motorway_logo.png';

const { publicRuntimeConfig } = getConfig();

const { DEALER_WEBAPP_URL } = publicRuntimeConfig;

const HOST = DEALER_WEBAPP_URL;

const robotsKey = 'robots';
const googlebotKey = 'googlebot';
const botsMetaConfig = [
	{
		key: robotsKey,
		name: robotsKey,
	},
	{
		key: googlebotKey,
		name: googlebotKey,
	},
];

const MetaTags = ({
	customTags = null,
	description = '',
	height = 1024,
	image,
	imagePrependHost = false,
	includeCanonicalTag = false,
	noIndex = false,
	noIndexFollow,
	ogDescription = '',
	ogTitle = '',
	router: { asPath, pathname },
	title,
	url,
	width = 1024,
}) => {
	const productionHostname = 'https://pro.motorway.co.uk';
	const hasDescription = ogDescription || description;
	const formattedImage = imagePrependHost ? `${HOST}${image}` : image;
	const metaImagePath = formattedImage || `${HOST}${metaImage.src}`;

	return (
		<Head>
			<title>{title}</title>
			{/* Added the key property to avoid duplicate meta tags */}
			{description && <meta key="description" content={description} name="description" property="description" />}

			<meta key="og-url" content={`${HOST}${url || asPath}`} name="og:url" property="og:url" />
			<meta key="og-type" content="website" name="og:type" property="og:type" />
			<meta key="og-title" content={ogTitle || title} name="og:title" property="og:title" />

			{hasDescription && (
				<meta key="og-description" content={hasDescription} name="og:description" property="og:description" />
			)}

			<meta key="og-image" content={metaImagePath} name="og:image" property="og:image" />
			<meta key="og-image-alt" content={title} name="og:image:alt" property="og:image:alt" />
			<meta key="og-image-width" content={width} name="og:image:width" property="og:image:width" />
			<meta key="og-image-height" content={height} name="og:image:height" property="og:image:height" />

			<meta key="twitter-card" content="summary_large_image" name="twitter:card" property="twitter:card" />
			<meta key="twitter-site" content="@motorwayhq" name="twitter:site" property="twitter:site" />
			<meta key="twitter-creator" content="@motorwayhq" name="twitter:creator" property="twitter:creator" />
			<meta key="twitter-title" content={ogTitle || title} name="twitter:title" property="twitter:title" />

			{hasDescription && (
				<meta
					key="twitter-description"
					content={hasDescription}
					name="twitter:description"
					property="twitter:description"
				/>
			)}

			{includeCanonicalTag && pathname && (
				<link key="canonical" href={`${productionHostname}${pathname}`} rel="canonical" />
			)}

			<meta key="twitter-image" content={metaImagePath} name="twitter:image" property="twitter:image" />

			{customTags}

			{!isProd && botsMetaConfig.map((config) => <meta key={config.key} content="none" name={config.name} />)}
			{isProd &&
				noIndex &&
				botsMetaConfig.map((config) => <meta key={config.key} content="noindex" name={config.name} />)}
			{isProd &&
				noIndexFollow &&
				botsMetaConfig.map((config) => <meta key={config.key} content="noindex,follow" name={config.name} />)}
		</Head>
	);
};

MetaTags.propTypes = {
	customTags: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	description: PropTypes.string,
	height: PropTypes.number,
	image: PropTypes.string,
	imagePrependHost: PropTypes.bool,
	includeCanonicalTag: PropTypes.bool,
	noIndex: PropTypes.bool,
	ogDescription: PropTypes.string,
	ogTitle: PropTypes.string,
	router: PropTypes.shape({
		asPath: PropTypes.string.isRequired,
		pathname: PropTypes.string.isRequired,
	}).isRequired,
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	width: PropTypes.number,
};

export default withRouter(MetaTags);
