import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ImageLazy.module.scss';

class ImageLazy extends React.Component {
	constructor(props) {
		super(props);

		this.$img = React.createRef();

		this.state = {
			loaded: false,
		};
	}

	componentDidMount() {
		if (this.$img.current.complete) {
			this.handleImageLoad();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { loaded } = this.state;
		return loaded !== nextState.loaded;
	}

	handleImageLoad = () => {
		this.setState({ loaded: true });
	};

	render() {
		const { altText = 'image', className, pngSource, webpSource } = this.props;

		const { loaded } = this.state;

		return (
			<div className={cx(className, styles.pictureWrapper, { [styles.loaded]: loaded })}>
				<picture>
					{webpSource && <source srcSet={webpSource.src} type="image/webp" />}
					{pngSource && <img ref={this.$img} alt={altText} onLoad={this.handleImageLoad} src={pngSource.src} />}
				</picture>
			</div>
		);
	}
}

ImageLazy.defaultProps = {
	altText: '',
	className: '',
	pngSource: undefined,
	webpSource: undefined,
};

ImageLazy.propTypes = {
	altText: PropTypes.string,
	className: PropTypes.string,
	pngSource: PropTypes.shape({
		src: PropTypes.string,
	}),
	webpSource: PropTypes.shape({
		src: PropTypes.string,
	}),
};

export default ImageLazy;
