import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from '../analytics.const';

const { HOMEPAGE } = ANALYTICS_CATEGORIES;

export const triggerTestimonialVideosOpenModalEvent = (): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: HOMEPAGE,
			customData: { label: 'Video Testimonial' },
			name: 'dlr_homepage_testimonial_videos_open_modal_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerTestimonialVideosCloseModalEvent = (): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: HOMEPAGE,
			customData: { label: 'Video Testimonial' },
			name: 'dlr_homepage_testimonial_videos_close_modal_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerMotorwayMoveLinkClicked = (linkPosition: 'page_top' | 'page_bottom'): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: HOMEPAGE,
			customData: { button_label: 'Motorway Move', label: linkPosition },
			name: 'dlr_homepage_motorway_move_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerMotorwayPayLinkClicked = (linkPosition: 'page_top' | 'page_bottom'): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: HOMEPAGE,
			customData: { button_label: 'Motorway Pay', label: linkPosition },
			name: 'dlr_homepage_motorway_pay_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
