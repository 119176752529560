import React from 'react';
import cx from 'classnames';
import T from 'i18n-react';
import Link from 'next/link';

import { BuyIcon, ContactFreeIcon, ListIcon, PriceTagIcon } from '@motorway/motorway-storybook-cra';
import { Button, CameraIcon, PaymentSpeedIcon, ProfileIcon, VehicleDriverSideIcon } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import cypressIds from 'Components/cypress_ids.json';
import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';
import { triggerBrowseVehiclesButtonClicked, triggerSignUpNowLinkClicked } from 'Services/analytics/events';
import {
	triggerMotorwayMoveLinkClicked,
	triggerMotorwayPayLinkClicked,
} from 'Services/analytics/events/homePageEvents';
import Content from 'Storybook/local/Content/Content';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import { useVehicleListButtonConfigWithUnauthedFallback } from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';

import ImageLazy from '../ImageLazy/ImageLazy';
import MobileBreak from '../shared/MobileBreak/MobileBreak';
import texts from '../texts.json';

import mastheadLeftPng from './images/masthead_left.png';
import mastheadLeftWebp from './images/masthead_left.webp';
import mastheadLeftNewPng from './images/masthead_left_new.png';
import mastheadLeftNewWebp from './images/masthead_left_new.webp';
import mastheadRightPng from './images/masthead_right.png';
import mastheadRightWebp from './images/masthead_right.webp';
import mastheadRightNewPng from './images/masthead_right_new.png';
import mastheadRightNewWebp from './images/masthead_right_new.webp';

import styles from './Masthead.module.scss';

T.setTexts(texts);

const HomepageMasthead: React.FC = () => {
	const isAuth = useIsAuth();
	const { as, href } = useVehicleListButtonConfigWithUnauthedFallback({ unauthorizedFallback: 'signUp' });
	const isNewLandingPageEnabled = useFeatureToggle(FEATURES.newLandingPage);

	const {
		maxWidth: { breakpointTablet },
	} = useBreakpoint();

	return isNewLandingPageEnabled ? (
		<Content
			center
			className={cx(styles.content, styles.newContent, { [styles.hidden]: false })}
			wrapper={{ className: styles.contentWrapper }}
		>
			<div className={cx(styles.heroBlock, styles.newHeroBlock)}>
				<ImageLazy
					altText="Masthead Image"
					className={cx(styles.mastheadImage, styles.mastheadImageLeft)}
					pngSource={mastheadLeftNewPng}
					webpSource={mastheadLeftNewWebp}
				/>
				<ImageLazy
					altText="Masthead Image"
					className={cx(styles.mastheadImage, styles.mastheadImageRight)}
					pngSource={mastheadRightNewPng}
					webpSource={mastheadRightNewWebp}
				/>
				<h1>{T.translate('homePage.hero.title')}</h1>
				<h2>
					{T.translate('homePage.hero.newDetail', { mobileBreak: <MobileBreak /> })}
					<b>{T.translate('homePage.hero.mwSells')}</b>
				</h2>
				<Link passHref as={as} href={href}>
					<Button
						fullWidth
						{...applyCypressData(cypressIds.buttons.hero)}
						label={T.translate(`homePage.hero.anchorButtonLabel${isAuth ? ':signin' : ''}`)}
						onClick={() =>
							isAuth ? triggerBrowseVehiclesButtonClicked('page_top') : triggerSignUpNowLinkClicked('page_top')
						}
						size={breakpointTablet ? 'medium' : 'large'}
					/>
				</Link>
			</div>
			<ul className={styles.newUspBlocks}>
				<li>
					<VehicleDriverSideIcon />
					<h3>{T.translate('homePage.hero.usp.point1.newTitle')}</h3>
					<p>{T.translate('homePage.hero.usp.point1.newDetail')}</p>
				</li>
				<li>
					<CameraIcon />
					<h3>{T.translate('homePage.hero.usp.point2.title')}</h3>
					<p>{T.translate('homePage.hero.usp.point2.newDetail')}</p>
				</li>
				<li>
					<ProfileIcon />
					<h3>{T.translate('homePage.hero.usp.point3.newTitle')}</h3>
					<p>{T.translate('homePage.hero.usp.point3.newDetail')}</p>
				</li>
				<li>
					<PaymentSpeedIcon />
					<h3>{T.translate('homePage.hero.usp.point4.newTitle')}</h3>
					<p>
						{T.translate('homePage.hero.usp.point4.newDetail', {
							motorwayMove: (
								<Hyperlink
									as="a"
									href="/motorway-move"
									label={T.translate('homePage.hero.usp.point4.motorwayMove')}
									onClick={() => triggerMotorwayMoveLinkClicked('page_top')}
									target="_blank"
								/>
							),
							motorwayPay: (
								<Hyperlink
									as="a"
									href="/motorway-pay"
									label={T.translate('homePage.hero.usp.point4.motorwayPay')}
									onClick={() => triggerMotorwayPayLinkClicked('page_top')}
									target="_blank"
								/>
							),
						})}
					</p>
				</li>
			</ul>
		</Content>
	) : (
		<Content
			center
			className={cx(styles.content, { [styles.hidden]: false })}
			wrapper={{ className: styles.contentWrapper }}
		>
			<div className={styles.heroBlock}>
				<ImageLazy
					altText="Masthead Image"
					className={cx(styles.mastheadImage, styles.mastheadImageLeft)}
					pngSource={mastheadLeftPng}
					webpSource={mastheadLeftWebp}
				/>
				<ImageLazy
					altText="Masthead Image"
					className={cx(styles.mastheadImage, styles.mastheadImageRight)}
					pngSource={mastheadRightPng}
					webpSource={mastheadRightWebp}
				/>
				<h1>{T.translate('homePage.hero.title')}</h1>
				<h2>
					{T.translate('homePage.hero.detail', {
						mobileBreak: <MobileBreak />,
					})}
				</h2>
				<Link passHref as={as} href={href}>
					<Button
						fullWidth
						{...applyCypressData(cypressIds.buttons.hero)}
						label={T.translate(`homePage.hero.anchorButtonLabel${isAuth ? ':signin' : ''}`)}
						onClick={() =>
							isAuth ? triggerBrowseVehiclesButtonClicked('page_top') : triggerSignUpNowLinkClicked('page_top')
						}
					/>
				</Link>
			</div>
			<ul className={styles.uspBlocks}>
				<li>
					<BuyIcon height="24" width="24" />
					<h3>{T.translate('homePage.hero.usp.point1.title')}</h3>
					<p>{T.translate('homePage.hero.usp.point1.detail')}</p>
				</li>
				<li>
					<ListIcon height="24" width="24" />
					<h3>{T.translate('homePage.hero.usp.point2.title')}</h3>
					<p>{T.translate('homePage.hero.usp.point2.detail')}</p>
				</li>
				<li>
					<PriceTagIcon height="24" width="24" />
					<h3>{T.translate('homePage.hero.usp.point3.title')}</h3>
					<p>{T.translate('homePage.hero.usp.point3.detail')}</p>
				</li>
				<li>
					<ContactFreeIcon height="24" width="24" />
					<h3>{T.translate('homePage.hero.usp.point4.title')}</h3>
					<p>{T.translate('homePage.hero.usp.point4.detail')}</p>
				</li>
			</ul>
		</Content>
	);
};

export default HomepageMasthead;
