import React from 'react';
import cx from 'classnames';

import styles from './MakeLogo.module.scss';

export const MakeLogo: React.FC<{
	[key: string]: unknown;
	className?: string;
	element?: React.ElementType;
	make: string;
	small?: boolean;
	webp?: boolean;
}> = ({ className = '', element = 'div', make, small = false, webp = false, ...props }) => {
	const Element = element;

	const classNames = cx(className, styles.makeLogo, styles[make], {
		[styles.small]: small,
		[styles.webp]: webp,
	});

	return <Element className={classNames} {...props} />;
};
