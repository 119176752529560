import React from 'react';
import cx from 'classnames';

import { FaceHappyIcon } from '@motorway/mw-highway-code';

import styles from 'Components/StaticPages/DiscoverMWPay/FAQSection/VideoModal/VideoModal.module.scss';
import Drawer from 'Storybook/core/Drawer/Drawer';
import { Variant } from 'Types/variant';

export type VideoModalProps = {
	dismissModal: () => void;
	youtubeVideoId: string;
};

export type VideoModalContentProps = Pick<VideoModalProps, 'youtubeVideoId'> & {
	autoplay?: boolean;
};

export const VideoModalContent: React.FC<VideoModalContentProps> = ({ autoplay = true, youtubeVideoId }) => {
	let src = '';
	if (youtubeVideoId) {
		src = `https://www.youtube.com/embed/${youtubeVideoId}`;
		if (autoplay) {
			src += '?autoplay=1';
		}
	}

	return (
		<div className={cx(styles.videoContainer, styles.center)}>
			<div className={cx(styles.videoAR)}>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					data-chromatic="ignore"
					height="100%"
					loading="lazy"
					src={src}
					style={{ border: 0 }}
					title="Hear from our dealers how nothing sells like Motorway"
					width="100%"
				></iframe>
				<FaceHappyIcon data-thc-icon />
			</div>
		</div>
	);
};

export const VideoModal: React.FC<VideoModalProps> = ({ dismissModal, youtubeVideoId }) => {
	return (
		<Drawer
			isOpen
			className={styles.drawer}
			direction="bottom"
			onClose={dismissModal}
			size="full"
			title="Hear from our dealers how nothing sells like Motorway"
			variant={Variant.SECONDARY}
		>
			<VideoModalContent youtubeVideoId={youtubeVideoId} />
		</Drawer>
	);
};
