import React from 'react';
import cx from 'classnames';
import T from 'i18n-react';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import {
	triggerMotorwayMoveLinkClicked,
	triggerMotorwayPayLinkClicked,
} from 'Services/analytics/events/homePageEvents';
import useFeesStore from 'Stores/FeesStore/FeesStore';
import Content from 'Storybook/local/Content/Content';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import ImageLazy from '../ImageLazy/ImageLazy';
import texts from '../texts.json';

import step1Png from './images/how_it_works_step_1.png';
import step1Webp from './images/how_it_works_step_1.webp';
import newStep1PngMobile from './images/how_it_works_step_1_mobile_new.png';
import newStep1WebpMobile from './images/how_it_works_step_1_mobile_new.webp';
import newStep1Png from './images/how_it_works_step_1_new.png';
import newStep1Webp from './images/how_it_works_step_1_new.webp';
import step2Png from './images/how_it_works_step_2.png';
import step2Webp from './images/how_it_works_step_2.webp';
import newStep2Png from './images/how_it_works_step_2_new.png';
import newStep2Webp from './images/how_it_works_step_2_new.webp';
import step3Png from './images/how_it_works_step_3.png';
import step3Webp from './images/how_it_works_step_3.webp';
import newStep3PngMobile from './images/how_it_works_step_3_mobile_new.png';
import newStep3WebpMobile from './images/how_it_works_step_3_mobile_new.webp';
import newStep3Png from './images/how_it_works_step_3_new.png';
import newStep3Webp from './images/how_it_works_step_3_new.webp';
import step4Png from './images/how_it_works_step_4.png';
import step4Webp from './images/how_it_works_step_4.webp';
import newStep4Png from './images/how_it_works_step_4_new.png';
import newStep4Webp from './images/how_it_works_step_4_new.webp';
import step5Png from './images/how_it_works_step_5.png';
import step5Webp from './images/how_it_works_step_5.webp';
import newStep5Png from './images/how_it_works_step_5_new.png';
import newStep5Webp from './images/how_it_works_step_5_new.webp';
import type { ImagePair, ImageQuad } from './HowItWorks.types';

import styles from './HowItWorks.module.scss';

export const imagesByStep: ImagePair[] = [
	[step1Png, step1Webp],
	[step2Png, step2Webp],
	[step3Png, step3Webp],
	[step4Png, step4Webp],
	[step5Png, step5Webp],
];

export const newImagesByStep: ImageQuad[] = [
	[newStep1Png, newStep1Webp, newStep1PngMobile, newStep1WebpMobile],
	[newStep2Png, newStep2Webp],
	[newStep3Png, newStep3Webp, newStep3PngMobile, newStep3WebpMobile],
	[newStep4Png, newStep4Webp],
	[newStep5Png, newStep5Webp],
];

T.setTexts(texts);

export const StepRender = (
	[pngSource, webpSource, pngMobileSource, webpMobileSource]: ImageQuad,
	index: number,
): React.ReactElement => {
	const { PROXY_BID_INCREMENT } = useFeesStore();
	const stepIndex = index + 1;
	const isNewLandingPageEnabled = useFeatureToggle(FEATURES.newLandingPage);

	return isNewLandingPageEnabled ? (
		<div key={`step${stepIndex}`} className={cx(styles.stepRow, styles[`newStepRow${stepIndex}`])}>
			<div className={styles.leftBlock}>
				{pngMobileSource && (
					<ImageLazy
						altText={`Step ${stepIndex}`}
						className={styles.mobileImage}
						pngSource={pngMobileSource}
						webpSource={webpMobileSource}
					/>
				)}
				<ImageLazy
					altText={`Step ${stepIndex}`}
					className={pngMobileSource ? styles.desktopImage : ''}
					pngSource={pngSource}
					webpSource={webpSource}
				/>
			</div>
			<div className={`${styles.rightBlock} ${styles.newRightBlock}`}>
				<span>{stepIndex}</span>
				<h3>{T.translate(`homePage.howItWorks.step${stepIndex}.newTitle`)}</h3>
				<p>
					{T.translate(`homePage.howItWorks.step${stepIndex}.newDescription`, {
						motorwayMove: (
							<Hyperlink
								as="a"
								href="/motorway-move"
								label={T.translate('homePage.hero.usp.point4.motorwayMove')}
								onClick={() => triggerMotorwayMoveLinkClicked('page_bottom')}
								size="large"
								target="_blank"
							/>
						),
						motorwayPay: (
							<Hyperlink
								as="a"
								href="/motorway-pay"
								label={T.translate('homePage.hero.usp.point4.motorwayPay')}
								onClick={() => triggerMotorwayPayLinkClicked('page_bottom')}
								size="large"
								target="_blank"
							/>
						),
					})}
				</p>
			</div>
		</div>
	) : (
		<div key={`step${stepIndex}`} className={cx(styles.stepRow, styles[`stepRow${stepIndex}`])}>
			<div className={styles.leftBlock}>
				<ImageLazy altText={`Step ${stepIndex}`} pngSource={pngSource} webpSource={webpSource} />
			</div>
			<div className={styles.rightBlock}>
				<span>{stepIndex}</span>
				<h3>{T.translate(`homePage.howItWorks.step${stepIndex}.title`)}</h3>
				<p>{T.translate(`homePage.howItWorks.step${stepIndex}.description`, { value: PROXY_BID_INCREMENT })}</p>
			</div>
		</div>
	);
};

export const ImagesSteps: React.FC = () => <>{imagesByStep.map(StepRender)}</>;
export const NewImagesSteps: React.FC = () => <>{newImagesByStep.map(StepRender)}</>;

const HowItWorks: React.FC = () => {
	const isNewLandingPageEnabled: boolean = useFeatureToggle(FEATURES.newLandingPage);

	return (
		<>
			<div className={styles.anchorLink} id="howitworks" rel="help" />
			<Content
				className={styles.section}
				element="section"
				wrapper={{ className: `${styles.sectionWrapper} ${isNewLandingPageEnabled ? styles.newSection : ''}` }}
			>
				<h2>{T.translate('homePage.howItWorks.title')}</h2>
				<div className={styles.stepsWrapper}>{isNewLandingPageEnabled ? <NewImagesSteps /> : <ImagesSteps />}</div>
			</Content>
		</>
	);
};

export default HowItWorks;
