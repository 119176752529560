import React, { FC } from 'react';
import cx from 'classnames';

import { Icon } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { VisuallyHidden } from '@motorway/mw-highway-code/src/components/__primitives__/VisuallyHidden';

import { ReactComponent as PlayIcon } from './play-icon.svg';
import { VideoPosterProps } from './VideoPoster.types';

import styles from './VideoPoster.module.scss';

const PlayIconComponent: FC = () => (
	<span className={styles.playButton}>
		<Icon>
			<PlayIcon />
		</Icon>
	</span>
);

export const VideoPoster: FC<VideoPosterProps> = ({
	alt,
	className,
	customThumbnail,
	onClick,
	orientation = 'left',
	showPlayButton = true,
	youtubeVideoId,
}) => {
	return (
		<div className={cx(styles.videoPoster, className)}>
			<Hyperlink
				data-thc-hyperlink
				data-orientation={orientation}
				data-testid="video-poster"
				href={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
				label={
					<>
						<VisuallyHidden>{alt}</VisuallyHidden>
						<figure aria-hidden="true" className={styles.poster} role="presentation">
							{showPlayButton ? <PlayIconComponent /> : null}
							{customThumbnail || (
								<img
									alt={alt}
									decoding="async"
									loading="lazy"
									src={`https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`}
								/>
							)}
						</figure>
					</>
				}
				onClick={(e: React.MouseEvent) => {
					if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) {
						return;
					}

					e.preventDefault();
					onClick?.();
				}}
				target="_blank"
			/>
		</div>
	);
};
