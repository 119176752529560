import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSessionStorage } from 'usehooks-ts';

import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useUser from 'Utilities/hooks/useUser';
import { KycStatus } from 'Utilities/types/kyc.types';

import { kycToShowOTPModal, useHandleKycStatus } from './useHandleKycStatus';
import { useStoreOtpPhoneValidated } from './useStoreOtpPhoneValidated';

export const QUERYPARAM_ORIGIN_KEY = 'origin';
export const QUERYPARAM_ORIGIN_VALUE = 'emailotpsetup';
export const SESSION_STORAGE_KEY = 'shoulShowOtpSetup';

export enum MODAL_OTP_SETUP_STATUS {
	INIT = 'init',
	PENDING = 'Pending',
	SHOWN = 'Shown',
	SUCCESS = 'Success',
}

export const useOtpSetup = (): {
	setModalState: (state: MODAL_OTP_SETUP_STATUS) => void;
	showOtpModal: boolean;
	showSuccessOtpSetup: boolean;
} => {
	const { query } = useRouter();
	const { isLoggedOut, otpPhone, phoneConfirmedAt } = useUser();
	const { kycStatus } = useHandleKycStatus({ source: 'setup' });
	const [isStoredOtpPhoneValidated] = useStoreOtpPhoneValidated(false);

	const isPaymentsOTPEnabled = useFeatureToggle(FEATURES.paymentsOTP);
	const [modalState, setModalState] = useSessionStorage(SESSION_STORAGE_KEY, MODAL_OTP_SETUP_STATUS.INIT);
	const kycStatusAllowOtpModal = kycToShowOTPModal.has(kycStatus as KycStatus);
	const isOtpSetUp = otpPhone && phoneConfirmedAt;
	const isOriginMailOtp = query?.[QUERYPARAM_ORIGIN_KEY] === QUERYPARAM_ORIGIN_VALUE;

	useEffect(() => {
		if ((!isOtpSetUp && modalState === MODAL_OTP_SETUP_STATUS.INIT && !isLoggedOut) || isOriginMailOtp) {
			setModalState(MODAL_OTP_SETUP_STATUS.PENDING);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps -- only run on mount

	const showOtpModal =
		!isOtpSetUp &&
		isPaymentsOTPEnabled &&
		kycStatusAllowOtpModal &&
		!!otpPhone &&
		!isLoggedOut &&
		((modalState === MODAL_OTP_SETUP_STATUS.PENDING && !isStoredOtpPhoneValidated) ||
			modalState === MODAL_OTP_SETUP_STATUS.SUCCESS);

	const showSuccessOtpSetup = (showOtpModal && isOtpSetUp) || modalState === MODAL_OTP_SETUP_STATUS.SUCCESS;

	return {
		setModalState,
		showOtpModal,
		showSuccessOtpSetup,
	};
};
