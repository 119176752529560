import { MODAL_OTP_SETUP_STATUS, useOtpSetup } from 'Utilities/hooks/useOtpSetup';

import { OtpSetupModal, OtpSetupModalVariant } from './OtpSetupModal';

export const OtpSetupModalContainer: React.FC = () => {
	const { setModalState, showOtpModal, showSuccessOtpSetup } = useOtpSetup();

	return (
		<OtpSetupModal
			onDismiss={() => {
				setModalState(MODAL_OTP_SETUP_STATUS.SHOWN);
			}}
			onSuccess={() => setModalState(MODAL_OTP_SETUP_STATUS.SUCCESS)}
			show={showOtpModal}
			variant={showSuccessOtpSetup ? OtpSetupModalVariant.SUCCESS : OtpSetupModalVariant.FORM}
		/>
	);
};
