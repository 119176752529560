import React from 'react';
import cx from 'classnames';

import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';

import { useVideoModal } from './VideoModal/useVideoModal';
import { VideoModal } from './VideoModal/VideoModal';
import { VideoPoster } from './VideoPoster/VideoPoster';
import { TestimonialVideos } from './NewTestimonials.types';

import styles from './Testimonials.module.scss';

export const TestimonialVideo: React.FC<TestimonialVideos> = ({ description, heading, youtubeVideoId }) => {
	const { closeModal: closeVideoModal, openModal: openVideoModal, state: isVideoModalOpen } = useVideoModal();

	const {
		minWidth: { breakpointTablet: isDesktop },
	} = useBreakpoint();

	return (
		<>
			<section className={styles.testimonialVideo}>
				<div className={cx(styles.container)}>
					<div className={styles.text}>
						<h3>{heading}</h3>
						<p>{description}</p>
					</div>
					<VideoPoster
						alt={description}
						onClick={openVideoModal}
						orientation={isDesktop ? 'all' : 'top'}
						showPlayButton={true}
						youtubeVideoId={youtubeVideoId}
					/>
				</div>
			</section>
			{isVideoModalOpen && <VideoModal dismissModal={closeVideoModal} youtubeVideoId={youtubeVideoId} />}
		</>
	);
};
