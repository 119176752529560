/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useState } from 'react';

import {
	triggerTestimonialVideosCloseModalEvent,
	triggerTestimonialVideosOpenModalEvent,
} from 'Services/analytics/events/homePageEvents';

export const useVideoModal = () => {
	const [state, setState] = useState<boolean>(false);

	const closeModal = useCallback(() => {
		triggerTestimonialVideosCloseModalEvent();
		setState(false);
	}, []);

	const openModal = useCallback(() => {
		triggerTestimonialVideosOpenModalEvent();
		setState(true);
	}, []);

	useEffect(() => {
		document.body.style.overflow = state ? 'hidden' : '';
	}, [state]);

	return {
		closeModal,
		openModal,
		state,
	};
};
